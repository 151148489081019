.xcompliant-page {
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 7em;
    padding: 4em 5em;
    background: linear-gradient(168.93deg, rgba(0, 86, 210, 0.1113) 1.02%, rgba(207, 113, 158, 0.1134) 74.53%);
    align-items: center;
    .page-header {
        width:100%;
        // TODO
        max-width: 1450px;
    }
    .page-body {
        display: flex;
        flex-grow: 1;
        gap: 2rem;
        position: relative;
        flex-direction: row;
        z-index: 0;
        width:100%;
        // TODO
        max-width: 1450px;
        .left-block {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 2em;
            padding-top: 2em;
            max-width: 100%;
            svg {
                max-width: 100%;
            }

            & > img {
                margin-bottom: 0.5rem;
            }

            img {
                width: max-content;
            }

            .plans-cardList {
                // margin-top: 4rem;
                display: flex;
                flex-direction: row;
                gap: 2rem;
            }
        }
        .right-block {
            display: flex;
            // align-items: flex-end;
            justify-content: flex-end;
            max-width: 35.5rem;
            // TODO: make it specific
            width: 30%;
            align-items: flex-start;
            img {
                width: 100%;
            }
        }

        @media (max-width: 1156px) {
            flex-wrap: wrap;
            justify-content: center;
            .left-block {
                .plans-cardList {
                    justify-content: center;
                }
            }
            & > * {
                width: 100%;
                flex: none !important;
            }
        }

        @media (max-width: 808px) {
            .left-block {
                & > img, .text-24, .text-36 {
                    text-align: center;
                    place-self: center;
                }
                .text-24, .text-36 {
                 width: 100% !important;
                }
                .page-button {
                    display: flex;
                    justify-content: center;
                }
                .plans-cardList {
                    flex-wrap: wrap;
                    gap: 6.8rem;
                    // margin-top: 2rem !important;
                    & > * {
                        width: 100%;
                    }
                }
            }
        }

    }
    .page-footer {
        display: flex;
        width: 100%;
        gap: 2rem;
        background: #fff;
        position: relative;
        padding: 0 5rem;
        min-height: 215px;
        > div {
            flex: 1 1 calc(calc(100% / 2) - 2rem);
        }
        .footer-left {
            position: relative;
            img {
                position: relative;
                top: -30px;
                max-width: 100%;
            }
        }
        .footer-right {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: relative;
            padding: 1rem 1rem;

            .footer-input {
                .text-12 {
                    margin-bottom: 0.5rem;
                }
            }

            .footer-btn {
                position: absolute;
                bottom: 1.5rem;
                right: -3rem;
            }
        }
    }
}

@media (max-width: 450px) {
    .page {
        padding: 2rem !important;

        .page-body {
            .left-block {
                .accept-btn {
                    width: 100%;
                    button {
                        width: 100%;
                    }
                }
            }
        }

        .page-footer {
            padding: 0 1rem;
            padding-bottom: 1rem;

            .footer-right {
                .footer-btn {
                    width: 100%;
                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 780px) {
    .page {
        .page-body {

            .right-block {
                justify-content: center !important;
                img {
                    max-width: 100%;
                }
            }

            > div {
                justify-content: center;
                flex: 1 1 100%;
            }
        }

        .page-footer {
            flex-wrap: wrap;
            height: max-content;
            min-height: unset;
            gap: 0 !important;
            > div {
                flex: 1 1 100%;
            }

            .footer-left {
                display: flex;
                justify-content: center;
            }

            .footer-right {
                .footer-btn {
                    position: unset;
                    align-self: flex-end;
                }
            }
        }
    }
}
