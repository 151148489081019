.text-16,
.text-24,
.text-36 {
  font-style: normal;
}

.text-16,
.text-24 {
  font-style: normal;
}

.text-12 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #202223;
}

.text-14 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #202223;
}

.text-16 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #202223;
}

.text-24 {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #202223;
}

.text-36 {
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
}

.text-30 {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 30px;
  line-height: 40px;
}

h1 {
  // font-family: 'Roboto';
  // font-style: normal;
  // font-size: 30px;
  // line-height: 40px;
  // font-weight: 500;
  // color: #1F2533;
}

.font-weight-500 {
  font-weight: 500;
}

.blue-text {
  // color: rgb(35, 130, 163) !important;
  color: var(--blueText);
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.rows-align-left {
  align-items: flex-start;  
}

.rows-align-center {
  align-items: center;  
}

.font-weight-500 {
  font-weight:  500;
}

.bold-text {
  font-weight: bold;
}

html {
  --colorBackground: #fff;
  --blueText: #0056D2;
}

.InputElement  {
  color: red;
}

.App {
  font-size: 10px;
  .ant-form, .ant-card {
    font-size: unset !important;
  }
  h1.ant-typography {
    margin-bottom: 0 !important;
  }
  div.ant-typography {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: rgb(93, 93, 93) !important;
    margin-bottom: 0 !important;
  }
  
  --baseFontSize: 10px;
}

.domain-input.ant-input-disabled {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
}