.mobile-preview {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    // background-color: none;
    // box-shadow: 0 5px 30px rgba(60, 80, 170, 0.1);

    > svg {
        height: 100%;
        width: 100%;
        max-height: 600px;
        // max-height: 350px;
        // max-width: 198px;
    }
}