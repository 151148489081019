.dashboard {
    // padding: 4rem 3rem;

    .dashboardContent {
        display: flex;
        flex-direction: column;
        gap: 2em;
        width: 66em;
        max-width: 100%;
        .ant-card {
            border-radius: 0.8em;
            overflow: hidden;
        }
    }
    .connect-block {
        display: flex;
        gap: 5rem;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        width: max-content;
        padding: 2rem 1.5rem;
        width: 620px;

        .text-14{
            text-align: left;
        }
    }

    @media (max-width: 395px) {
        .connect-block {
            flex-wrap: wrap;

            .text-14{
                   word-break: normal;
            }

            > span {
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 680px) {
        .connect-block {
            max-width: 100%;
        }
    }
}
