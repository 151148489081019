@import '~antd/dist/antd.less';

.ant-steps-item-title::after {
  background: #ADB2BB;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: #ADB2BB;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: #ADB2BB;
}
@primary-color: #0056D2;