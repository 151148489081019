.script-form {
  width: 100%;
  // padding: 2em;
  text-align: unset;
  position: relative;

  .script-form-body {
    // padding: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
    position: relative;

    .not-onboarding {
      background: none;
      border: none;
      .ant-card-body {
        padding: 0;
      }
    }

    .script-form-previews {
      position: sticky;
      display: flex;
      justify-content: center;
      flex-direction: column;
      top: 20px;
      overflow: hidden;
      margin: 0;
      padding: 1em;
      height: max-content;
      width: 40%;
      gap: 2em;

      .scrip-preview {
        #PolarisTextField9 {
          max-height: 35em !important;
          background-color: #23241f;
          color: white;
        }
      }
    }

    .script-block-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      // width: 70%;
      gap: 20px;

      .script-form-block {
        padding: 2em;
        width: 100%;
        background: #EDF3FF;
        border: none;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05),
          0px 0px 1px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        .block-title {
          text-align: left;
          width: 100%;
          padding-bottom: 20px;
          font-size: 22px;
          letter-spacing: -0.5px;
          font-weight: bold;
          line-height: 1.3;
          color: #1f2533;
          font-family: Norms, Arial, Helvetica, sans-serif;
          text-rendering: geometricPrecision;
          margin: 0;
        }

        .block-body {
          text-align: left;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          font-size: 15px;
          // column-gap: 6%;

          > .ant-form-item {
            width: 48%;
          }

          // > span {
          //   width: 45%;
          // }

          @media (max-width: 680px) {
            // > span {
            > .ant-form-item {
              width: 100%;
            }
          }

          .icon-block {
            width: 100%;
            .ant-radio-group {
              width: 100%;
              .ant-radio-wrapper {
                margin-right: 0;
                margin-bottom: 16px;
                width: 20%;
                span.ant-radio + * {
                  padding-right: 16px;
                  padding-left: 16px;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 930px) {
      .script-form-previews {
        height: 100%;
        width: 100%;
        margin-bottom: 20px;
        padding: 2em;
        position: initial;
      }
      .script-block-list {
        width: 100%;
      }
    }
  }

  .script-form-footer {
    margin-top: 2em;
    width: 100%;
    // position: absolute;
    left: 0;
    top: 100%;
    // box-shadow: inset 0px 1px 0px #e4e5e7;
    .footer-content {
      // padding: 1.5em 1em;
      display: flex;
      justify-content: flex-start;
      gap: 20px;
    }
  }
}

.wordpress-wrapper .script-form-footer {
  position: static !important;
}