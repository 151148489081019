.planCard-container {
  &.planCard-inactive {
    filter: grayscale(70%);
    pointer-events: none;
  }
  max-width: 35.6em;
  width: 100%;
  background: none;
  position: relative;

  .plan-mark {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -33%);
    width: 38%;

    @media (max-width: 450px) {
      transform: translate(14%, -50%) !important;
    }
    @media (max-width: 380px) {
      transform: translate(16%, -50%) !important;
    }
    @media (max-width: 352px) {
      transform: translate(18%, -50%) !important;
    }
    @media (max-width: 338px) {
      transform: translate(19%, -50%) !important;
    }
    @media (max-width: 324px) {
      transform: translate(20%, -50%) !important;
    }
  }
  .ant-card {
    width: 100%;
    height: 100%;
    border-radius: 10px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .planCard-header, .ant-card-head {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: unset;
      line-height: unset;
      color: #202223;
      background: #E9F7FF;
      box-shadow: 0px 2px 2px #DDE4F1;
      height: 7em;
      .ant-card-head-title {
        font-size: 3em;
      }
    }

    .ant-card-body {
      flex-grow: 1;
    }

    .planCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      .planFeatures {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 12px;
        > div {
          margin-bottom: 12px;
          color: #6D7175;
          text-align: center;
        }
        .blue-text {
          font-weight: 600;
        }
      }
      .planCard-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        width: 100%;
        height: 115px;
        .planCard-old-price-container {
          height: 26px;
          position: relative;
          // display: flex;
          // align-items: center;
          // flex-direction: column;
          .planCard-price {
            color: #6D7175;
            display: flex;
            align-items: center;
            height: 26px;
            gap: 2px;
            .priceValue {
              font-size: 15px;
            }
            .priceText {
              font-size: 8px;
            }
          }
        }

        .planCard-price-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          .planCard-price {
            display: flex;
            flex-direction: row;
            gap: 0.4em;
            margin-top: 1em;
            margin-bottom: 1em;
            align-items: flex-end;
            .priceValue {
              font-weight: 600;
              font-size: 2.4em;
              line-height: 109%;
              color: var(--blueText);
            }

            .priceText {
              font-style: normal;
              font-weight: 600;
              font-size: 1.2em;
              line-height: 119%;
              color: #6d7175;
              padding-bottom: 0.1em;
            }
          }

          .priceHelpWrapper {
            display: flex;
            gap: 5px;
            align-items: center;
            margin-bottom: 16px;
            .priceHelp {
              color: #6D7175;
              font-size: 11px;
              line-height: 22px;
            }
            .saveTag {
              padding: 5px 10px;
              line-height: 17px;
              font-size: 14px;
              color: #6D7175;
              background: #E9F7FF;
              border-radius: 10px;
            }
          }

          .priceLabel {
            font-style: normal;
            font-weight: normal;
            font-size: 1.3em;
            line-height: 123%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #202223;
            padding: 2px 8px;
            border-radius: 10px;
            min-height: 20px;
            margin-bottom: 10px;
            background: #A4E8F2;
            border: 1px solid #D9D9D9;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
            border-radius: 2px;
          }
        }

        .planCard-bodyText {
          color: #6d7175;
          margin-bottom: 20px;
          font-size: 14px;
        }
      }

      .planCard-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
