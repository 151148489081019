@keyframes analyze-progress {
  from {
    stroke-dashoffset: 466px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}

.analyze-progress-ellipse-animate {
  animation-name: analyze-progress;
  animation-duration: 50s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}